<template>
  <div class="pb-32">
    <t-button class="mb-4" @click="onAddAllItems">{{
      $t("action.select_all_items")
    }}</t-button>

    <p class="mb-2">{{ $t("checklist.intro_popup") }}</p>

    <div
      class="mb-2 rounded-lg p-4 shadow-lg transition"
      :class="{
        'bg-white text-brand-dark': !itemInArray(item),
        'bg-brand-navblue text-white': itemInArray(item),
      }"
      v-for="item in Object.values(exampleItems)"
      :key="item"
      @click="onAddItem(item)"
    >
      {{ item }}
    </div>

    <t-button
      class="mt-8"
      :disabled="!chosenItems.length"
      @click="addItemsToChecklist"
      >{{ $t("checklist.add") }}</t-button
    >
  </div>
</template>

<script>
import MyEventRepository from "../../repositories/MyEventRepository";
import UserRepository from "../../repositories/UserRepository";

export default {
  name: "ExampleTodos",

  props: {
    eventUuid: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      exampleItems: this.$t("checklist.items"),
      chosenItems: [],
    };
  },

  methods: {
    onAddItem(item) {
      if (this.itemInArray(item)) {
        this.chosenItems = this.chosenItems.filter(
          (chosenItem) => chosenItem !== item
        );
        return;
      }

      this.chosenItems.push(item);
    },

    onAddAllItems() {
      this.chosenItems = Object.values(this.exampleItems);
    },

    itemInArray(description) {
      return this.chosenItems.includes(description);
    },

    async addItemsToPersonalChecklist() {
      try {
        const { data } = await UserRepository.bulkAddTodos([
          ...new Set(this.chosenItems),
        ]);

        if (data.success) {
          this.$emit("bulkCreateTodosSuccess");
        }

        this.chosenItems = [];
      } catch (e) {
        console.log(e);
      }
    },

    async addItemsToEventChecklist() {
      try {
        const { data } = await MyEventRepository.bulkAddTodos(this.eventUuid, [
          ...new Set(this.chosenItems),
        ]);

        if (data.success) {
          this.$emit("bulkCreateTodosSuccess");
        }

        this.chosenItems = [];
      } catch (e) {
        console.log(e);
      }
    },

    async addItemsToChecklist() {
      if (this.eventUuid) {
        this.addItemsToEventChecklist();
      } else {
        this.addItemsToPersonalChecklist();
      }
    },
  },
};
</script>

<style scoped></style>
