<template>
  <div>
    <t-select :options="options" class="mb-4 text-black" v-model="activeComponent"></t-select>

    <keep-alive>
      <component @bulkCreateTodosSuccess="success" :is="activeComponent" @success="success"></component>
    </keep-alive>
  </div>
</template>

<script>
import ChecklistForm from "components/Checklist/Form";
import ExampleTodos from "../Event/ExampleTodos.vue";

export default {
  components: {
    ChecklistForm,
    ExampleTodos,
  },

  data() {
    return {
      activeComponent: "example-todos",
      options: [
        {
          text: this.$t("checklist.suggested_title"),
          value: "example-todos",
        },
        {
          text: this.$t("event.btn--add-todo"),
          value: "checklist-form",
        },
      ],
    };
  },

  methods: {
    success() {
      this.$emit("success");
    },
  },
};
</script>

<style></style>
