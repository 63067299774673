<template>
  <div class="relative transition duration-500 info-checklist">
    <portal to="topbar-left" v-if="!formOpen">
      <router-link :to="{ name: 'profile' }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 22" class="w-6 h-6 text-brand-dark">
          <g fill="currentColor" fill-rule="evenodd" transform="translate(-4 -1)">
            <path
              d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z" />
          </g>
        </svg>
      </router-link>
    </portal>

    <div class="flex flex-col max-w-screen-sm min-h-full p-4 mx-auto space-y-4">
      <div class="flex flex-row justify-between mb-2">
        <div class="flex items-center">
          <h2 class="mr-2 text-xl font-bold">{{ $t("pages.my-checklist") }}</h2>

          <div class="relative">
            <icon-info class="w-5 h-5 fill-current dark:text-white" @click="infoOpen = !infoOpen" />

            <transition name="fade">
              <div class="absolute left-0 z-50 min-h-full p-2 mx-auto mt-2 text-xs leading-normal bg-white rounded-lg shadow  top w-52 text-brand-dark" v-if="infoOpen">
                <p>{{ $t("checklist.intro") }}</p>
              </div>
            </transition>
          </div>
        </div>

        <div class="flex items-start justify-end flex-1">
          <div class="flex flex-row items-center justify-end flex-grow-0 flex-shrink-0 space-x-2">
            <t-button variant="small" @click="addTodo">
              <icon-plus class="w-3 h-3" />

              <span class="ml-2 text-xs truncate">{{ $t("checklist.btn--add-todo") }}</span>
            </t-button>
          </div>
        </div>
      </div>

      <loading v-if="loading"></loading>

      <div v-else-if="checklist && checklist.length" class="mt-10 space-y-2">
        <div :key="task.uuid" v-for="task in checklist" class="relative flex items-center">
          <div class="absolute flex items-center space-x-1 left-1">
            <div class="flex items-center justify-center w-12 h-12 bg-red-200 rounded-lg">
              <icon-loading v-if="isDeleting" class="w-4 h-4 text-red-400 fill-current animate-spin" />
              <icon-trash v-else @click="deleteChecklistItem(task)" class="w-6 h-6 text-red-600 fill-current" />
            </div>
          </div>

          <checklist-item data-draggable="true" class="relative transition-all checklist__item"
            @touchmove.native="handleSwipe" @touchstart.native="handleSwipeStart"
            @touchend.native="handleSwipeEnd($event, 55)" :task="task" @editTask="editTodo" />
        </div>
      </div>

      <div v-else>
        <div class="relative flex items-center justify-center my-12">
          <icon-checklist class="relative z-20 w-20 h-20" />
          <blob class="absolute z-10 w-64 h-64 opacity-25" />
        </div>

        <div class="text-sm text-center">
          {{ $t("checklist.empty--checklist") }}
        </div>
      </div>
    </div>

    <popup-pane :dark="isCheckedIn" v-if="formOpen" :title="$t(`checklist.title--${activeTodo ? 'edit' : 'add'}-todo`)"
      @close="formOpen = false">
      <checklist-form v-if="activeTodo" @success="handleFormSuccess" :todo="activeTodo"></checklist-form>
      <checklist-personal v-else @success="handleFormSuccess"></checklist-personal>
    </popup-pane>
  </div>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
import IconPlus from "assets/icons/plus.svg";
import IconTrash from "assets/icons/trash-bin.svg";
import IconInfo from "assets/icons/info.svg";
import PopupPane from "components/PopupPane";
import ChecklistForm from "components/Checklist/Form.vue";
import ChecklistPersonal from "components/Checklist/Personal.vue";
import ChecklistItem from "components/Checklist/ChecklistItem.vue";
import Loading from "components/Shared/Loading.vue";
import { mapGetters } from "vuex";
import Swipe from "../../mixins/Swipe";
import IconChecklist from "assets/icons/clipboard.svg";
import Blob from "assets/icons/blob.svg";

const TodoRepository = Repository.get("todos");

export default {
  name: "info-checklist",
  mixins: [Swipe],
  components: {
    IconPlus,
    IconInfo,
    IconTrash,
    PopupPane,
    ChecklistItem,
    ChecklistPersonal,
    ChecklistForm,
    Loading,
    IconChecklist,
    Blob
  },
  data: () => ({
    formOpen: false,
    exampleTodosOpen: false,
    checklist: [],
    loading: true,
    isDeleting: false,
    activeTodo: undefined,
    infoOpen: false,
  }),

  methods: {
    handleFormSuccess() {
      this.formOpen = false;
      this.loadChecklist();
    },

    async deleteChecklistItem(task) {
      this.isDeleting = true;

      await TodoRepository.delete(task.uuid);
      const { data } = await TodoRepository.all();
      this.checklist = data.todos;

      this.isDeleting = false;
    },

    loadChecklist() {
      this.loading = true;

      TodoRepository.all()
        .then((response) => {
          this.checklist = response.data.todos;
        })
        .then(() => {
          this.loading = false;
        });
    },

    editTodo(todo) {
      this.activeTodo = todo;
      this.formOpen = true;
    },

    addTodo() {
      this.activeTodo = null;
      this.formOpen = true;
    },

    onBulkCreateSuccess() {
      this.loadChecklist();
      this.exampleTodosOpen = false;
    },
  },

  computed: {
    ...mapGetters(["isCheckedIn"]),
  },

  mounted() {
    this.loadChecklist();
  },
};
</script>
