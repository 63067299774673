<template>
  <form
    @submit.prevent="submitForm"
    @keydown="form.onKeydown($event)"
    name="checklist-todo"
    class="flex flex-col space-y-4"
    autocomplete="off"
  >
    <t-input-group>
      <template v-slot:label>
        {{ $t("checklist.form.description") }}
      </template>

      <t-input
        v-model="form.description"
        max="250"
        class="text-black"
        required
        :variant="{ danger: form.errors.has('description') }"
        :placeholder="$t('checklist.form.placeholder--description')"
      />
    </t-input-group>

    <t-button type="submit" :disabled="form.busy">{{
      form.busy ? $t("action.saving") : this.todo ? $t("checklist.btn--update-todo") : $t("checklist.btn--save-todo")
    }}</t-button>

    <template v-if="todo && todo.uuid">
      <t-button type="button" :disabled="!todo || form_loading || delete_loading" variant="link" class="text-red-500" @click="deleteTodo">{{
        delete_loading ? $t("action.deleting") : $t("action.delete")
      }}</t-button>
    </template>
  </form>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
import { Form } from "vform";
const TodoRepository = Repository.get("todos");

export default {
  name: "ChecklistForm",
  props: {
    todo: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  mounted() {
    this.fillForm();
  },

  data: () => ({
    form: new Form({
      description: "",
    }),
    form_loading: false,
    form_error: "",
    delete_loading: false,
  }),

  methods: {
    fillForm() {
      if (this.todo) {
        this.form.description = this.todo.description;
      }
    },

    deleteTodo() {
      if (!this.todo) return;
      this.delete_loading = true;
      if (!confirm(this.$i18n.t("checklist.confirm--delete"))) {
        this.delete_loading = false;
        return;
      }
      TodoRepository.delete(this.todo.uuid)
        .then(() => {
          this.$emit("checklist-updated");
          this.$emit("success");
        })
        .catch((err) => {
          console.log("catched uncatched error", err.response);
          this.formErrors = [err.response.message || "Failed to delete event."];
        })
        .then(() => {
          this.delete_loading = false;
        });
    },

    submitForm() {
      this.form_loading = true;
      if (this.todo) {
        this.update(this.todo.uuid);
      } else {
        this.create();
      }
    },

    create() {
      const { description } = this.form;
      TodoRepository.create({ description })
        .then((response) => {
          this.$emit("checklist-updated", response.data.todo);
          this.$emit("success");
          this.form.reset();
        })
        .catch((err) => {
          console.log("catched uncatched error", err.response);
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors);
                this.form_error = err.response.data.message;
                return;
            }
          }
          this.formErrors = [this.$i18n.t("unknown_error")];
        })
        .then(() => {
          this.form_loading = false;
        });
    },

    update(uuid) {
      const { description } = this.form;
      TodoRepository.update(uuid, { description })
        .then((response) => {
          this.$emit("checklist-updated", response.data.todo);
          this.$emit("success");
          this.form.reset();
        })
        .catch((err) => {
          console.log("catched uncatched error", err.response);
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors);
                this.form_error = err.response.data.message;
                return;
            }
          }
          this.formErrors = [this.$i18n.t("unknown_error")];
        })
        .then(() => {
          this.form_loading = false;
        });
    },
  },
};
</script>
